import { Animate, Card, Paginate, PlanOption, SubNav, Table, useAPI } from 'components/lib'
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import CampaignStats from './CampaignStats'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import UserInfo from './UserInfo'

function Campaign() {
    const { id } = useParams()
    const navigate = useNavigate()

    const [searchParams] = useSearchParams()

    const [logsOffset, setLogsOffset] = useState(0)
    const logsLimit = 50

    const [linkLogsOffset, setLinkLogsOffset] = useState(0)
    const linkLogsLimit = 50

    const { data, loading, refetch } = useAPI(`/api/campaigns/${id}`)
    const logs = useAPI(`/api/campaigns/${id}/logs?offset=${logsOffset}&limit=${logsLimit}`)
    const linkLogs = useAPI(`/api/campaigns/${id}/linklogs?offset=${logsOffset}&limit=${logsLimit}`)

    const [activeTab, setActiveTab] = useState('logs')

    const tabs = [
        { action: () => setActiveTab('logs'), label: 'logs', data: logs },
        { action: () => setActiveTab('opt_out'), label: 'opt_out' },
        { action: () => setActiveTab('links'), label: 'links' },
        { action: () => setActiveTab('link_logs'), label: 'link_logs', actions: { custom: [{ icon: 'eye', action: (data) => navigate(`/campaigns/${id}/link_log/${data.id}`) }] } },
        { action: () => setActiveTab('plan_options'), label: 'plan_options', }
    ]

    const [plans, setPlans] = useState([])

    const plansMutation = useMutation({
        mutationKey: ['save_campaign_plan_options'],
        mutationFn: () => axios.put(`/api/campaigns/${id}/plan_options`, plans)
    })

    useEffect(() => {
        if (data?.plans) {
            setPlans(data.data.plans)
        }

    }, [data?.data])


    // const [needReload, setNeedReload] = useState(null)

    // const { on } = useSocket()

    // useEffect(() => {
    //     on(`campaign_${id}_stats`, () => {
    //         setNeedReload(Date.now())
    //         refetch()
    //     })

    // }, [on])

    const tableData = activeTab === 'logs' ? logs?.data?.results :
        activeTab === 'link_logs' ? linkLogs?.data?.results :
            data?.[activeTab]

    return (
        <Animate loading={loading}>

            <UserInfo id={searchParams.get('user')} />

            <CampaignStats
                loading={loading}
                campaign={id}
                name={data?.campaign?.name}
                callforwarding_number={data?.campaign?.callforwarding_number}
            // needReload={needReload}
            />

            <Card loading={loading}>
                <SubNav
                    active={activeTab}
                    items={tabs}
                />

                <Animate key={activeTab}>

                    {
                        activeTab === 'logs' && (
                            <Paginate
                                offset={logsOffset}
                                limit={logsLimit}
                                total={logs?.data?.total}
                                loading={logs.loading}
                                onChange={x => setLogsOffset(x)}
                            />
                        )
                    }

                    {
                        activeTab === 'link_logs' && (
                            <Paginate
                                offset={linkLogsOffset}
                                limit={linkLogsLimit}
                                total={linkLogs?.data?.total}
                                loading={logs.loading}
                                onChange={x => setLinkLogsOffset(x)}
                            />
                        )
                    }

                    {
                        ['plan_options'].includes(activeTab) ? (
                            <div>
                                <PlanOption
                                    loading={loading}
                                    plans={plans}
                                    setPlans={setPlans}
                                    mutation={plansMutation}
                                />
                            </div>
                        ) : (
                            <Table
                                search
                                loading={loading}
                                show={Object.keys(tableData?.[0] || {}).filter(x => x !== 'id')}
                                data={tableData}
                                actions={tabs.find(tab => tab.label === activeTab)?.actions}
                                badge={{
                                    col: 'type', color: 'other', condition: [
                                        { value: 'STOP', color: 'red' },
                                        { value: 'START', color: 'green' },
                                        { value: 'HELP', color: 'yellow' },
                                        { value: 'INFO', color: 'orange' },
                                        { value: 'OPT_IN', color: 'green' },
                                        { value: 'OPT_OUT', color: 'red' },
                                    ]
                                }}
                            />
                        )
                    }
                </Animate>
            </Card>
        </Animate>
    )
}

export default Campaign