/***
*
*   APP LAYOUT
*   The mission control dashboard layout containing the navigation
*   and header (title, secondary nav)
*
*   PROPS
*   title: title of the view
*
**********/

import { Fragment, useContext } from 'react';
import { AuthContext, AppNav, Header, useAPI } from 'components/lib';
import Style from './app.module.scss';

export function AppLayout(props) {
  // context & style
  const context = useContext(AuthContext);

  const { data } = useAPI('/api/ui/nav');

  return (
    <Fragment>
      <AppNav
        type='popup'
        items={[
          { label: 'Dashboard', icon: 'activity', link: '/dashboard' },
          { label: 'Users', icon: 'users', link: '/users' },
          { label: 'Campaigns', icon: 'radio', link: '/campaigns' },

          { label: 'Coupons', icon: 'gift', link: '/coupons' },
          { label: 'Settings', icon: 'sliders', link: '/settings/workinghourssms' },
          { label: 'Marketing', icon: 'target', link: '/marketing/leads' },
          { label: 'Emails', icon: 'at-sign', link: '/emails' },
          { label: 'Short Links', icon: 'link', link: '/short_links' },


          { label: 'Errors', icon: 'alert-circle', link: '/errors', badge: data?.errors },
          { label: 'Logs', icon: 'clipboard', link: '/logs' },
          { label: 'Events', icon: 'clock', link: '/events' },
          { label: 'Support', icon: 'headphones', link: '/support', badge: data?.support },
          { label: 'Sign Out', icon: 'log-out', action: context.signout }
        ]}
      />

      <main className={Style.app}style={{
        ...process.env.REACT_APP_SERVER === 'DEV' && { marginTop: '2.5rem' }
      }}>

        <Header title={props.title} />
        {<props.children {...props.data} />}

      </main>
    </Fragment>
  );
}
