import { useMutation } from "@tanstack/react-query"
import { Button, Checkbox, useView } from "components/lib"
import { useState } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"

export default function Notes(props) {
    const { id } = useParams()
    const view = useView()


    const [data, setData] = useState({ notes_editable: props.notes_editable })

    const { mutate } = useMutation({
        mutationFn: (data) => axios.put(`/api/campaign/${id}/notes`, data).then(res => res.data),
        onSuccess: (data) => {
            view.notification.show(data?.message, 'success', true)
        },
        onError: (error) => {
            console.log(error)
            view.notification.show(error?.response?.data?.message, 'error', true)
        }
    })

    return (
        <div className="grid gap-3">
            <Checkbox
                option={'Notes Editable'}
                name={'notes_editable'}
                checked={data.notes_editable}
                onChange={(n, c) => setData(prev => ({ ...prev, [n]: c }))}
            />

            <Button small text={'Save'} action={() => mutate(data)} />
        </div>
    )
}