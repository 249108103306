import { useContext } from 'react';
import Axios from 'axios';
import { ViewContext, Card, Table, Animate, useAPI, useNavigate } from 'components/lib';

export function Users(props) {

  const viewContext = useContext(ViewContext);
  const users = useAPI('/api/user');
  const navigate = useNavigate()

  const editUser = (data, callback) => {
    viewContext.modal.show({

      title: 'Edit User',
      form: {
        firstname: {
          label: 'Firstname',
          type: 'text',
          value: data.firstname,
          required: true,
        },
        lastname: {
          label: 'Lastname',
          type: 'text',
          value: data.lastname,
          required: true,
        },
        email: {
          label: 'Email',
          type: 'email',
          value: data.email,
          required: true
        },
        phonenumber: {
          label: 'Phone Number',
          type: 'phone',
          value: data.phonenumber,
          required: true,
        },
        business_name: {
          label: 'Business Name',
          type: 'text',
          value: data.business_name,
          required: true,
        },
        business_address: {
          label: 'Business Address',
          type: 'text',
          value: data.business_address,
          required: true,
        },
        business_phonenumber: {
          label: 'Verified Phone Number',
          type: 'phone',
          value: data.business_phonenumber,
          required: true,
        },
        business_category: {
          label: 'Business Category',
          type: 'text',
          value: data.business_category,
          required: true,
        },
        status: {
          label: 'Status',
          type: 'select',
          default: data.status,
          caption: 'When suspending an account owner all the other child accounts cannot login until the owner is verified again.',
          options: [
            { label: 'Unverified', value: 'unverified', checked: true },
            { label: 'Verified', value: 'verified' },
            { label: 'Inactive', value: 'inactive' },
            { label: 'Suspended', value: 'suspended' },
          ],
          required: true,
          containerClass: 'col-span-2'
        },

        ...['master', 'owner'].includes(data.permission) && {
          business_verified: {
            type: 'checkbox',
            option: 'Business Verified',
            defaultChecked: data.business_verified,
          }
        },

        oldEmail: {
          value: data.email,
          type: 'hidden'
        }
      },
      inputsClassName: 'grid sm:grid-cols-2 gap-2',
      buttonText: 'Save',
      url: `/api/user/${data.id}`,
      method: 'PATCH'

    }, () => {
      viewContext.notification.show(data.name + ' was updated', 'success', true);
      users.refetch()
    });
  }

  const deleteUser = (data, callback) => {
    viewContext.modal.show({
      title: 'Delete User',
      form: {},
      buttonText: 'Delete User',
      text: `Are you sure you want to delete ${data.email}? This will cancel any active campaigns for this user.`,
      url: `/api/user/${data.id}`,
      method: 'DELETE',
      destructive: true,

    }, () => {

      viewContext.notification.show(`${data.email} was deleted`, 'success', true);
      callback();

    });
  }

  const impersonateUser = async (data) => {

    try {

      const res = await Axios.post(`/api/user/impersonate/${data.id}`);
      const token = res.data?.data?.token;
      if (token) {
        window.location = process.env.REACT_APP_REMOTE_CLIENT + `/signin/impersonate?token=${token}`
      }
    }
    catch (err) {
      viewContext.handleError(err);
    }
  }


  return (
    <Animate>
      <Card>
        <Table
          search
          data={users.data}
          loading={users.loading}
          show={["firstname", "lastname", "email", 'verified', 'added', 'status', 'permission', "verified_phone_number", "active_campaigns", "total_campaigns", "date_created", "last_active"]}
          badges={['status', 'added']}
          badge={[
            {
              col: 'status', color: 'gray', condition: [
                { value: 'unverified', color: 'yellow' },
                { value: 'verified', color: 'green' },
                { value: 'suspended', color: 'red' },
              ]
            },
            {
              col: 'added', color: 'gray', condition: [
                { value: 'Added user', color: 'orange' },
                { value: 'Added admin', color: 'blue' },
                { value: 'Account Owner', color: 'green' },
              ]
            },
          ]}
          actions={{
            edit: editUser,
            delete: deleteUser,
            email: true,
            custom: [
              { icon: 'log-in', action: impersonateUser },
              { icon: 'radio', action: (data) => navigate(`/campaigns/user/${data.id}`), title: 'Info & Campaigns' },
            ]
          }}
        />
      </Card>
    </Animate>
  );
}
